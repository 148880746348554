import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';

@Injectable()
export class HeaderInterceptor implements HttpInterceptor {
	constructor(private readonly cookieService: CookieService) {}

	intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		const authToken = this.cookieService.get('token');
		const authRequest = authToken
			? request.clone({
					setHeaders: {
						Authorization: `Bearer ${authToken}`,
					},
			  })
			: request;

		return next.handle(authRequest);
	}
}
