import { Injectable, inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { JwtService } from '../../services/jwt/jwt.service';
import { AuthService } from '../services/auth.service';

@Injectable()
export class AuthGuard {
	constructor(
		public router: Router,
		private readonly cookieService: CookieService,
		private readonly authService: AuthService,
		private readonly jwtService: JwtService
	) {}

	canActivate(): boolean {
		if (
			!this.cookieService.get('token') ||
			new Date().getTime() > new Date(this.cookieService.get('cookie_expiration')).getTime()
		) {
			this.authService.logOut();
			return false;
		} else return true;
	}
}

export const isLogged: CanActivateFn = () => {
	return inject(AuthGuard).canActivate();
};
