import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AuthService } from './core/authentication/services/auth.service';
import { JwtService } from './core/services/jwt/jwt.service';
import { LanguageService } from './core/services/language.service';
import { Language } from './shared/models/language.model';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
	showHeaderAndFooter: boolean = false;
	menuOptions: any = [];
	notificationNumber = '';
	languageOptions: Language[] = [
		{ label: 'ES', id: 'es' },
		{ label: 'EN', id: 'en' },
	];
	language: object | undefined = {};
	avatar = { id: '26', name: '' };

	constructor(
		private readonly languageService: LanguageService,
		private readonly router: Router,
		private readonly auth: AuthService,
		private readonly jwtService: JwtService
	) {
		this.languageService.autoResolveLanguage();
		this.showLayouts();
	}

	ngOnInit(): void {
		this.languageService.selectedLanguage$.subscribe((language) => {
			this.language = this.languageOptions.find((lang) => lang.id == language);
		});
	}

	private showLayouts() {
		this.router.events.subscribe((event) => {
			if (event instanceof NavigationEnd) {
				this.showHeaderAndFooter =
					event.url !== '/login' && !event.url.includes('/OTP') && !event.url.includes('/register');
				if (this.showHeaderAndFooter) {
					const jwt = this.jwtService.decompileToken();
					this.avatar = { id: jwt.data.userId, name: jwt.data.name };
				}
			}
		});
	}

	menuChangeValue(event: any) {
		if (event.id === '0') this.router.navigate(['/profile']);
		if (event.id === '1') this.auth.logOut();
	}

	selectLanguage(option: any) {
		this.languageService.setLanguage(option.id.toLowerCase());
	}
	onClick(option: number) {}
}
