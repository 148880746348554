import { createReducer, on } from '@ngrx/store';
import { Dictionary } from '../../models/dictionary.model';
import { MasterActions } from './master.action';

export const masterStateFeatureKey = 'masterState';

export interface MasterState {
	pipeline_status: Dictionary[];
	products: Dictionary[];
	companies?: Dictionary[];
	company_sizes: Dictionary[];
	partner_types: Dictionary[];
	countries: Dictionary[];
	amount_filter: Dictionary[];
	document_type: Dictionary[];
	last_operation: Dictionary[];
	company_category: Dictionary[];
	timezones: Dictionary[];
	request_id: string | null;
}
const initialState: MasterState = {
	pipeline_status: [],
	products: [],
	companies: [],
	company_sizes: [],
	partner_types: [],
	countries: [],
	amount_filter: [],
	document_type: [],
	last_operation: [],
	company_category: [],
	timezones: [],
	request_id: null,
};
export const masterReducer = createReducer(
	initialState,
	on(MasterActions.getMasterValuesSuccess, (current, action) => ({ ...current, ...action.master })),
	on(MasterActions.setRequestId, (current, action) => ({ ...current, request_id: action.request_id }))
);

export * from './master.reducer';
