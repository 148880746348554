import { createReducer, on } from '@ngrx/store';
import { UserActions } from './user.action';

export const userStateFeatureKey = 'userState';

export interface UserState {
	roleType: string | null;
}
const initialState: UserState = {
	roleType: '',
};
export const userReducer = createReducer(
	initialState,
	on(UserActions.setRolUser, (current, action) => ({ ...current, roleType: action.roleType }))
);

export * from './user.reducer';
