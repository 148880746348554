import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PaginationResponse } from 'src/app/core/models/pagination-response.model';
import { environment } from 'src/environments/environment';
import { Commission } from '../models/commission.model';

@Injectable({
	providedIn: 'root',
})
export class CommissionsService {
	constructor(private readonly http: HttpClient) {}

	getCommissions(
		pagination: { page: number; limit: number },
		params?: HttpParams
	): Observable<PaginationResponse<Commission>> {
		return this.http.get<PaginationResponse<Commission>>(`${environment.apiUrl}/comissions?page=${pagination.page}&limit=${pagination.limit}`, { params });
	}
}
