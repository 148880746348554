<tec-auth-layout
	img="/assets/images/tecalis-logo.svg"
	[imgTitle]="'LOGIN.OTP_PAGE.ACCOUNT' | translate"
	[title]="'LOGIN.OTP_PAGE.TITLE' | translate"
	[subTitle]="'LOGIN.OTP_PAGE.SUBTITLE' | translate : { email: email }"
>
	<ng-container body>
		<tec-alert status="error" *ngIf="errorOtp" [msg]="errorOtp" (closeAlert)="errorOtp = ''"></tec-alert>

		<tec-code-input (formValue)="codeOtpError = ''; errorOtp = ''; codeOtp = $event" [errorMsg]="codeOtpError">
		</tec-code-input>

		<tec-button size="lg" [label]="'LOGIN.OTP_PAGE.BUTTON' | translate" (clickEvent)="onContinue()"> </tec-button>
	</ng-container>

	<ng-container link>
		<p>
			{{ 'LOGIN.OTP_PAGE.INFO' | translate }}
			<a routerLink="/login"> {{ 'LOGIN.OTP_PAGE.LINK_RETRY' | translate }} </a>
		</p>
	</ng-container>
</tec-auth-layout>
