<tec-auth-layout
	img="/assets/images/tecalis-logo.svg"
	[imgTitle]="'REGISTER.PORTAL_PARTNER' | translate"
	[title]="'REGISTER.TITLE' | translate"
>
	<ng-container body>
		<ng-container *ngIf="step === 1">
			<tec-button-collapse
				[disableExpand]="true"
				[title]="'REGISTER.CONTINUE_GOOGLE' | translate"
				[img]="'/assets/images/google-g-icon.svg'"
				(click)="goToGoogle()"
			></tec-button-collapse>
			<tec-button-collapse
				[disableExpand]="true"
				[title]="'REGISTER.CONTINUE_MAIL' | translate"
				[icon]="'icon-mail'"
				(click)="goToEmail()"
			></tec-button-collapse>
		</ng-container>
		<ng-container [formGroup]="form" *ngIf="step === 2">
			<tec-button-collapse
				[disableExpand]="true"
				[title]="'REGISTER.CONTINUE_GOOGLE' | translate"
				[img]="'/assets/images/google-g-icon.svg'"
				(click)="goToGoogle()"
			></tec-button-collapse>
			<tec-form-group>
				<tec-input
					[placeholder]="'REGISTER.NAME_PLACEHOLDER' | translate"
					formControlName="name"
					[hasError]="nameFromControl.invalid && nameFromControl.touched"
				>
				</tec-input>
				<span *ngIf="nameFromControl.hasError('required') && nameFromControl.touched" class="form-group__error-message">
					{{ 'ERRORS.REQUIRED_FIELD' | translate }}
				</span>
			</tec-form-group>

			<tec-form-group>
				<tec-input
					[placeholder]="'REGISTER.SURNAME_PLACEHOLDER' | translate"
					formControlName="surnames"
					[hasError]="surnamesFromControl.invalid && surnamesFromControl.touched"
				>
				</tec-input>
				<span
					*ngIf="surnamesFromControl.hasError('required') && surnamesFromControl.touched"
					class="form-group__error-message"
				>
					{{ 'ERRORS.REQUIRED_FIELD' | translate }}
				</span>
			</tec-form-group>

			<tec-form-group>
				<tec-input
					[placeholder]="'REGISTER.EMAIL_PLACEHOLDER' | translate"
					formControlName="email"
					[hasError]="newPasswordFromControl.invalid && newPasswordFromControl.touched"
				>
				</tec-input>
				<span *ngIf="emailFromControl.hasError('required') && emailFromControl.touched" class="form-group__error-message">
					{{ 'ERRORS.REQUIRED' | translate }}
				</span>
				<span *ngIf="emailFromControl.hasError('email')" class="form-group__error-message">
					{{ 'ERRORS.PASSWORD_FORMAT' | translate }}
				</span>
			</tec-form-group>

			<tec-form-group>
				<tec-input
					[placeholder]="'REGISTER.PASSWORD_PLACEHOLDER' | translate"
					type="password"
					formControlName="newPassword"
					[hasError]="newPasswordFromControl.invalid && newPasswordFromControl.touched"
				>
				</tec-input>
				<span
					*ngIf="newPasswordFromControl.hasError('required') && newPasswordFromControl.touched"
					class="form-group__error-message"
				>
					{{ 'ERRORS.REQUIRED' | translate }}
				</span>
				<span *ngIf="newPasswordFromControl.hasError('pattern')" class="form-group__error-message">
					{{ 'ERRORS.PASSWORD_FORMAT' | translate }}
				</span>
			</tec-form-group>

			<tec-form-group>
				<tec-input
					[placeholder]="'REGISTER.CONFIRM_PASSWORD_PLACEHOLDER' | translate"
					type="password"
					formControlName="confirmNewPassword"
					[hasError]="confirmNewPasswordFromControl.invalid && confirmNewPasswordFromControl.touched"
				>
				</tec-input>
				<span
					*ngIf="confirmNewPasswordFromControl.hasError('required') && confirmNewPasswordFromControl.touched"
					class="form-group__error-message"
				>
					{{ 'ERRORS.REQUIRED' | translate }}
				</span>
				<span *ngIf="confirmNewPasswordFromControl.hasError('pattern')" class="form-group__error-message">
					{{ 'ERRORS.PASSWORD_FORMAT' | translate }}
				</span>
			</tec-form-group>
			<ng-container link>
				<p>
					{{ 'GENERAL.ACCEPT_TERM_1' | translate }}<tec-link routerLink="/login">{{ 'GENERAL.TERMS' | translate }}</tec-link
					>{{ 'GENERAL.ACCEPT_TERM_2' | translate
					}}<tec-link routerLink="/login">{{ 'GENERAL.POLICY' | translate }}</tec-link
					>{{ 'GENERAL.ACCEPT_TERM_3' | translate }}
				</p>
			</ng-container>
			<tec-button size="lg" [label]="'REGISTER.LOG_IN' | translate" (clickEvent)="onContinue()"> </tec-button>
		</ng-container>
	</ng-container>

	<ng-container link>
		<p>
			{{ 'REGISTER.INFO_LOGIN' | translate }}
			<tec-link routerLink="/login">{{ 'REGISTER.INFO_LOGIN_ACTION' | translate }}</tec-link>
		</p>
	</ng-container>
	<tec-watermark></tec-watermark>
</tec-auth-layout>
