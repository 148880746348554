import { GoogleSigninButtonModule, SocialAuthService } from '@abacritt/angularx-social-login';
import { CommonModule } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import {
	AlertComponent,
	AuthLayoutComponent,
	ButtonCollapseComponent,
	ButtonComponent,
	CheckboxComponent,
	CodeInputComponent,
	DividerComponent,
	FormGroupComponent,
	IconComponent,
	InputComponent,
	LinkComponent,
	SpinnerComponent,
} from '@tecalisdev/infinity';
import { catchError, throwError } from 'rxjs';
import { CookiesOptions } from 'src/app/core/authentication/models/cookies-options.model';
import { AuthService } from 'src/app/core/authentication/services/auth.service';
import { SpinnerService } from '../../shared/services/spinner.service';
import { CheckOtpComponent } from '../check-otp/check-otp.component';
declare global {
	interface Window {
		google: any;
	}
}
@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss'],
	standalone: true,
	imports: [
		CommonModule,
		TranslateModule,
		AuthLayoutComponent,
		IconComponent,
		LinkComponent,
		ButtonComponent,
		InputComponent,
		AlertComponent,
		DividerComponent,
		ButtonCollapseComponent,
		CheckboxComponent,
		FormGroupComponent,
		FormsModule,
		ReactiveFormsModule,
		SpinnerComponent,
		CodeInputComponent,
		CheckOtpComponent,
		RouterModule,
		GoogleSigninButtonModule,
	],
	providers: [AuthService, SpinnerService],
})
export class LoginComponent implements OnInit {
	@ViewChild('hiddenElement') hiddenElement: ElementRef;
	form: FormGroup;
	hasEmailFormError: boolean = false;
	hasPasswordFormError: boolean = false;
	errorLogin: boolean = false;
	showOTPPage: boolean = false;
	cookiesOptions: CookiesOptions = {
		expires: undefined,
		path: '/',
		domain: undefined,
		secure: true,
		sameSite: 'Strict',
	};

	constructor(
		private readonly fb: FormBuilder,
		public router: Router,
		readonly spinnerService: SpinnerService,
		private readonly auth: AuthService,
		private readonly googleAuth: SocialAuthService
	) {
		this.form = this.fb.group({
			username: ['', [Validators.required, Validators.email]],
			password: ['', Validators.required],
		});
	}

	ngOnInit(): void {
		this.googleAuth.authState.subscribe((user) => {
			let token: string;
			if (user) {
				token = user.idToken;
				this.auth.sentGoogleToken(token).subscribe(() => this.router.navigate(['/home']));
			}
		});
		this.listenForm();
	}

	logIn(): void {
		if (this.form.valid) {
			this.spinnerService.show();
			this.auth
				.logIn({ ...this.form.getRawValue(), needs_otp: true })
				.pipe(
					catchError(() => {
						this.spinnerService.hide();
						this.errorLogin = !this.errorLogin;
						this.hasEmailFormError = true;
						this.hasPasswordFormError = true;
						return throwError(() => 'Invalid Credentials');
					})
				)
				.subscribe(() => {
					this.router.navigate([`/OTP`, this.usernameFromControl.value]);
					this.spinnerService.hide();
				});
		}
	}

	private listenForm(): void {
		this.usernameFromControl.valueChanges.subscribe(() => {
			this.hasEmailFormError = this.usernameFromControl?.touched && this.usernameFromControl?.invalid;
		});
		this.passwordFromControl.valueChanges.subscribe(() => {
			this.hasPasswordFormError = this.passwordFromControl?.touched && this.passwordFromControl?.invalid;
		});
	}

	createFakeGoogleWrapper = () => {
		const googleLoginWrapper = document.createElement('div');
		googleLoginWrapper.style.display = 'none';
		googleLoginWrapper.classList.add('custom-google-button');
		document.body.appendChild(googleLoginWrapper);
		window.google.accounts.id.renderButton(googleLoginWrapper, {
			type: 'icon',
			width: '200',
		});

		const googleLoginWrapperButton = googleLoginWrapper.querySelector('div[role=button]') as HTMLElement;

		return {
			click: () => {
				googleLoginWrapperButton?.click();
			},
		};
	};

	handleGoogleLogin() {
		this.createFakeGoogleWrapper().click();
	}

	checkbox(isCheckboxSelected: boolean) {}

	get usernameFromControl(): FormControl {
		return this.form.get('username') as FormControl;
	}

	get passwordFromControl(): FormControl {
		return this.form.get('password') as FormControl;
	}
}
