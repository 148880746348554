import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ModalComponent } from '@tecalisdev/infinity';

@Component({
	selector: 'app-error-modal',
	templateUrl: './error-modal.component.html',
	styleUrls: ['./error-modal.component.scss'],
	providers: [ModalComponent],
})
export class ErrorModalComponent {
	constructor(private readonly dialogRef: MatDialogRef<ErrorModalComponent>) {}
	dialogDeleteAction(event: any) {
		event === 'buttonPrimary' ? this.dialogRef.close('retry') : this.dialogRef.close();
	}
}
