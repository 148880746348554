import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/authentication/guards/auth.guard';
import { SSOAuth } from './core/authentication/guards/ssoAuth.guard';
import { CheckOtpComponent } from './layout/check-otp/check-otp.component';
import { LoginComponent } from './layout/login/login.component';
import { RegisterComponent } from './layout/register/register.component';

const routes: Routes = [
	{
		path: 'login',
		component: LoginComponent,
	},
	{
		path: 'OTP/:email',
		component: CheckOtpComponent,
	},
	{
		path: '',
		redirectTo: 'home',
		pathMatch: 'full',
	},
	{
		path: 'home',
		canActivate: [AuthGuard],
		loadChildren: () => import('../app/features/home/home.module').then((m) => m.HomeModule),
	},
	{
		path: 'profile',
		canActivate: [AuthGuard],
		loadChildren: () => import('../app/features/profile/profile.module').then((m) => m.ProfileUserModule),
	},
	{
		path: 'commissions',
		canActivate: [AuthGuard],
		loadChildren: () => import('../app/features/commissions/commissions.module').then((m) => m.CommissionsModule),
	},
	{
		path: 'sales',
		canActivate: [AuthGuard],
		loadChildren: () => import('../app/features/sales/sales.module').then((m) => m.SalesModule),
	},
	{
		path: 'sso/token/:token',
		canActivate: [SSOAuth],
		loadChildren: () => import('../app/features/home/home.module').then((m) => m.HomeModule),
	},
	{
		path: 'register',
		component: RegisterComponent,
	},
	{ path: '**', redirectTo: 'home' },
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule],
})
export class AppRoutingModule {}
