<ng-container *ngIf="!(spinnerService.isLoading$ | async) && !showOTPPage; else spinner">
	<tec-auth-layout
		img="/assets/images/tecalis-logo.svg"
		imgTitle="Partner Portal"
		title="{{ 'LOGIN.TITLE' | translate }}"
	>
		<ng-container body [formGroup]="form">
			<tec-alert status="error" *ngIf="errorLogin" msg="{{ 'GENERAL.LOGIN_ERROR' | translate }}"></tec-alert>
			<tec-button-collapse
				[disableExpand]="true"
				[title]="'LOGIN.GOOGLE' | translate"
				[img]="'/assets/images/google-g-icon.svg'"
				(click)="handleGoogleLogin()"
			>
				<asl-google-signin-button
					data-use_fedcm_for_prompt="true"
					#hiddenElement
					type="icon"
					size="medium"
				></asl-google-signin-button>
			</tec-button-collapse>
			<tec-divider [label]="'LOGIN.OR_SIGN_IN_WITH' | translate"></tec-divider>
			<tec-form-group>
				<tec-input
					[placeholder]="'LOGIN.EMAIL' | translate"
					formControlName="username"
					type="email"
					[hasError]="hasEmailFormError"
				></tec-input>
				<span *ngIf="hasEmailFormError" class="form-group__error-message">{{ 'ERRORS.REQUIRED_FIELD' | translate }}</span>
			</tec-form-group>
			<tec-form-group>
				<tec-input
					[placeholder]="'LOGIN.PASSWORD' | translate"
					formControlName="password"
					type="password"
					[hasError]="hasPasswordFormError"
				></tec-input>
				<span *ngIf="hasPasswordFormError" class="form-group__error-message">{{
					'ERRORS.REQUIRED_FIELD' | translate
				}}</span>
			</tec-form-group>

			<div class="auth__body__actions">
				<tec-checkbox label="{{ 'LOGIN.REMEMBER' | translate }}" (valueChange)="checkbox($event)"></tec-checkbox>
				<!-- <tec-link>{{ 'LOGIN.FORGOT_YOUR_PASSWORD' | translate }}</tec-link> -->
			</div>
			<tec-button size="lg" label="{{ 'LOGIN.LOG_IN' | translate }}" (click)="logIn()"></tec-button>
		</ng-container>
	</tec-auth-layout>
</ng-container>
<ng-template #spinner>
	<tec-spinner [show]="true" [opaque]="true" [watermark]="true"></tec-spinner>
</ng-template>
<ng-container *ngIf="showOTPPage">
	<app-check-otp></app-check-otp>
</ng-container>
