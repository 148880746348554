import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PurchaseRol } from '../../sales/models/purchase-rol.enum';
import { IFilter, ITableHeader } from '@tecalisdev/infinity';
import { Period } from '../models/period.model';
import { Status } from '../models/status.model';
import { Product } from '../models/commission.model';

@Injectable({
	providedIn: 'root',
})
export class GetHeadersTable {
	constructor(private translate: TranslateService) {}

	get TableHeadersClients(): ITableHeader[] {
		return [
			{ label: this.translate.instant('COMMISSIONS.CLIENTS_TABLE.NAME') },
			{ label: this.translate.instant('COMMISSIONS.CLIENTS_TABLE.PRODUCTS') },
			{ label: this.translate.instant('COMMISSIONS.CLIENTS_TABLE.NUMBER_OF_CONTRACTS') },
			{ align: 'right', label: this.translate.instant('COMMISSIONS.CLIENTS_TABLE.COMMISSIONS_MONTH') },
		];
	}

	get TableHeadersSettlementRequest() {
		return [
			{ label: this.translate.instant('COMMISSIONS.SETTLEMENT_REQUESTS_TABLE.REQUEST_NUMBER') },
			{ label: this.translate.instant('COMMISSIONS.SETTLEMENT_REQUESTS_TABLE.INVOICE_DELIVERY') },
			{ label: this.translate.instant('COMMISSIONS.SETTLEMENT_REQUESTS_TABLE.PROCEEDINGS_NUMBER') },
			{ label: this.translate.instant('COMMISSIONS.SETTLEMENT_REQUESTS_TABLE.BILLING_PERIOD') },
			{ label: this.translate.instant('COMMISSIONS.SETTLEMENT_REQUESTS_TABLE.IMPORT') },
			{ label: this.translate.instant('COMMISSIONS.SETTLEMENT_REQUESTS_TABLE.REQUEST_DATE') },
			{ label: '' },
		];
	}

	get TableHeadersProceedings() {
		return [
			{ label: this.translate.instant('COMMISSIONS.PROCEEDINGS_TABLE.PROCEEDING_NUMBER') },
			{ label: this.translate.instant('COMMISSIONS.PROCEEDINGS_TABLE.NAME') },
			{ label: this.translate.instant('COMMISSIONS.PROCEEDINGS_TABLE.BILLING_PERIOD') },
			{ label: this.translate.instant('COMMISSIONS.PROCEEDINGS_TABLE.IMPORT') },
			{ label: this.translate.instant('COMMISSIONS.PROCEEDINGS_TABLE.GENERATE') },
		];
	}

	get tableHeadersSettlements() {
		return [
			{ label: this.translate.instant('COMMISSIONS.SETTLEMENTS_TABLE.INVOICE_NUMBER') },
			{ label: this.translate.instant('COMMISSIONS.SETTLEMENTS_TABLE.PARTNER') },
			{ label: this.translate.instant('COMMISSIONS.SETTLEMENTS_TABLE.PROCEEDINGS_NUMBER') },
			{ label: this.translate.instant('COMMISSIONS.SETTLEMENTS_TABLE.BILLING_PERIOD') },
			{ label: this.translate.instant('COMMISSIONS.SETTLEMENTS_TABLE.IMPORT') },
			{ label: this.translate.instant('COMMISSIONS.SETTLEMENTS_TABLE.STATUS') },
			{ label: this.translate.instant('COMMISSIONS.SETTLEMENTS_TABLE.ISSUANCE') },
		];
	}
}

@Injectable({
	providedIn: 'root',
})
export class GetFiltersTable {
	constructor(private translate: TranslateService) {}
	filtersClients(products: Product[]): IFilter[] {
		return [
			{
				type: 'select',
				name: 'product',
				title: this.translate.instant('COMMISSIONS.PRODUCT'),
				placeholder: this.translate.instant('COMMISSIONS.PRODUCT'),
				options: products.map((p, index) => {
					return { id: `${index + 1}`, label: p.name };
				}),
			},
			{
				type: 'select',
				name: 'sort',
				title: this.translate.instant('GENERAL.SORT'),
				placeholder: this.translate.instant('GENERAL.SORT'),
				options: [
					{ id: PurchaseRol.INFLUYENT, label: this.translate.instant('CONTACTS.INFLUYENT') },
					{ id: PurchaseRol.RESPONSIBLE_DECISION, label: this.translate.instant('CONTACTS.RESPONSIBLE_DECISION') },
				],
			},
		];
	}

	filtersSettlement(period: Period[], status: Status[]): IFilter[] {
        return [
			{
				type: 'select',
				name: 'billing_period',
				title: this.translate.instant('COMMISSIONS.SETTLEMENT_REQUESTS_TABLE.BILLING_PERIOD'),
				placeholder: this.translate.instant('COMMISSIONS.SETTLEMENT_REQUESTS_TABLE.BILLING_PERIOD'),
                options: period.map((p, index) => {
					return { id: `${index + 1}`, label: p.name };
				}),
			},
			{
				type: 'select',
				name: 'status',
				title: this.translate.instant('COMMISSIONS.SETTLEMENT_REQUESTS_TABLE.STATUS'),
				placeholder: this.translate.instant('COMMISSIONS.SETTLEMENT_REQUESTS_TABLE.STATUS'),
				options: status.map((s, index) => {
					return { id: `${index + 1}`, label: s.name };
				}),
			},
			{
				type: 'select',
				name: 'sort',
				title: this.translate.instant('GENERAL.SORT'),
				placeholder: this.translate.instant('GENERAL.SORT'),
				options: [
				],
			},
		];
	}

	get filtersProceedings(): IFilter[] {
		return [
			{
				type: 'select',
				name: 'billing_period',
				title: this.translate.instant('COMMISSIONS.PROCEEDINGS_TABLE.BILLING_PERIOD'),
				placeholder: this.translate.instant('COMMISSIONS.PROCEEDINGS_TABLE.BILLING_PERIOD'),
				options: [],
			},
		];
	}
}