<tec-base>
	<tec-topnav
		*ngIf="showHeaderAndFooter"
		img="/assets/images/tecalis-logo.svg"
		title="Partner Portal"
		[menu]="[
			{ label: 'HEADER.HOME' | translate, url: '/home' },
			{ label: 'HEADER.SALES' | translate, url: '/sales' },
			{ label: 'HEADER.COMISSIONS' | translate, url: '/commissions' }
		]"
	>
		<tec-select [options]="languageOptions" [value]="language" (changeValue)="selectLanguage($event)"></tec-select>

		<tec-button-icon
			icon="icon-help-circle"
			[attr.data-tooltip]="'HEADER.HELP' | translate"
			(clickEvent)="onClick(1)"
		></tec-button-icon>

		<tec-button-icon
			[icon]="'icon-bell'"
			[badge]="notificationNumber"
			[attr.data-tooltip]="'HEADER.ALERT' | translate"
			(clickEvent)="onClick(2)"
		></tec-button-icon>

		<tec-menu
			tooltip="{{ avatar.name }}"
			[trigger]="true"
			[options]="[
				{ label: 'HEADER.OPTIONS.PROFILE' | translate, icon: 'icon-users', id: '0' },
				{ label: 'HEADER.OPTIONS.LOG_OUT' | translate, icon: 'icon-log-out', id: '1', divider: true, style: 'red' }
			]"
			(changeValue)="menuChangeValue($event)"
		>
			<tec-avatar [avatar]="avatar"></tec-avatar>
		</tec-menu>
	</tec-topnav>
	<router-outlet id="content"></router-outlet>
	<!-- <tec-watermark></tec-watermark> -->
	<tec-footer
		*ngIf="showHeaderAndFooter"
		[links]="[
			{ name: 'FOOTER.PLANS' | translate, href: '/help' },
			{ name: 'FOOTER.HELP' | translate, href: '/help' }
		]"
	></tec-footer>
</tec-base>
