import { createFeatureSelector, createSelector } from '@ngrx/store';
import { MasterState, masterStateFeatureKey } from './master.reducer';

const masterState = createFeatureSelector<MasterState>(masterStateFeatureKey);

export const master = createSelector(masterState, (masterState) => masterState);
export const pipelineStatus = createSelector(masterState, (masterState) => masterState.pipeline_status);
export const products = createSelector(masterState, (masterState) => masterState.products);
export const companies = createSelector(masterState, (masterState) => masterState.companies);
export const companySizes = createSelector(masterState, (masterState) => masterState.company_sizes);
export const partnerTypes = createSelector(masterState, (masterState) => masterState.partner_types);
export const countries = createSelector(masterState, (masterState) => masterState.countries);
export const amountFilter = createSelector(masterState, (masterState) => masterState.amount_filter);
export const documentType = createSelector(masterState, (masterState) => masterState.document_type);
export const requestId = createSelector(masterState, (masterState) => masterState.request_id);
export const lastOperation = createSelector(masterState, (masterState) => masterState.last_operation);
export const companyCategory = createSelector(masterState, (masterState) => masterState.company_category);
export const timeZones = createSelector(masterState, (masterState) => masterState.timezones);

export * as MasterSelectors from './master.selector';
