<tec-dashboard-layout *ngIf="dashBoard$ | async as dashBoard; else spinner">
	<!-- Acciones rápidas -->
	<!-- View partner regular & partner admin -->
	<ng-container hightlight *ngIf="!isSuperAdmin; else tecalisAdmin">
		<section>
			<article class="tile tile--transparent">
				<div class="tile__header">
					<h5>{{ 'DASHBOARD.QUICK_ACTIONS' | translate }}</h5>
				</div>
				<div class="tile__body">
					<div class="grid grid-e5">
						<tec-card-access
							[type]="'default'"
							[size]="'sm'"
							[disabled]="false"
							[description]="'CONTACTS.CREATE_CONTACT' | translate"
							[tagImage]="{ icon: 'icon-agenda', status: 'default' }"
							(click)="createContacts()"
						></tec-card-access>
						<tec-card-access
							[type]="'default'"
							[size]="'sm'"
							[disabled]="false"
							[description]="'COMPANIES.CREATE_COMPANY' | translate"
							[tagImage]="{ icon: 'icon-store', status: 'default' }"
							(click)="createCompany()"
						></tec-card-access>
						<tec-card-access
							[type]="'default'"
							[size]="'sm'"
							[disabled]="false"
							[description]="'BUSINESS.CREATE_BUSINESS' | translate"
							[tagImage]="{ icon: 'icon-briefcase', status: 'default' }"
							(click)="createBusiness()"
						></tec-card-access>
						<tec-card-access
							[type]="'secondary'"
							[size]="'sm'"
							[disabled]="false"
							[title]="''"
							[description]="'SALES.PIPELINE' | translate"
							[tagImage]="{ icon: 'icon-pipeline', status: 'default' }"
							(click)="goToPipeLine()"
						></tec-card-access>
						<tec-card-access
							[type]="'secondary'"
							[size]="'sm'"
							[disabled]="false"
							[title]="''"
							[description]="'SALES.CLIENTS' | translate"
							[tagImage]="{ icon: 'icon-customer', status: 'default' }"
							(click)="goToClients()"
						></tec-card-access>
					</div>
				</div>
			</article>
		</section>
	</ng-container>

	<!-- View tecalis admin -->
	<ng-template #tecalisAdmin hightlight>
		<section>
			<article class="tile">
				<div class="tile__header">
					<h5>{{ 'DASHBOARD.QUICK_ACTIONS' | translate }}</h5>
				</div>
				<div class="tile__body">
					<div class="grid grid-e5">
						<tec-card-access
							[type]="'default'"
							[size]="'sm'"
							[disabled]="false"
							[description]="'PARTNERS.CREATE_PARTNER' | translate"
							[tagImage]="{ icon: 'icon-agenda', status: 'default' }"
							(click)="createPartners()"
						></tec-card-access>
						<tec-card-access
							[type]="'default'"
							[size]="'sm'"
							[disabled]="false"
							[description]="'USERS.CREATE_USER' | translate"
							[tagImage]="{ icon: 'icon-users', status: 'default' }"
							(click)="createUser()"
						></tec-card-access>
						<tec-card-access
							[type]="'secondary'"
							[size]="'sm'"
							[disabled]="false"
							[title]="''"
							[description]="'SALES.CLIENTS' | translate"
							[tagImage]="{ icon: 'icon-customer', status: 'default' }"
							(click)="goToClients()"
						></tec-card-access>
						<tec-card-access
							[type]="'secondary'"
							[size]="'sm'"
							[disabled]="false"
							[title]="''"
							[description]="'SALES.BUSINESS' | translate"
							[tagImage]="{ icon: 'icon-briefcase', status: 'default' }"
							(click)="goToBusiness()"
						></tec-card-access>
						<tec-card-access
							[type]="'secondary'"
							[size]="'sm'"
							[disabled]="false"
							[title]="''"
							[description]="'SALES.COMPANIES' | translate"
							[tagImage]="{ icon: 'icon-pipeline', status: 'default' }"
							(click)="goToCompanies()"
						></tec-card-access>
					</div>
				</div>
			</article>
		</section>
	</ng-template>

	<!-- Cabecera resumen -->
	<ng-container dashboard *ngIf="!isSuperAdmin">
		<div class="grid grid-e4">
			<tec-stats
				[title]="'CONTACTS.TITLE' | translate"
				[value]="contacts"
				[tagImage]="{ icon: 'icon-user', status: 'level' }"
				[size]="'default'"
			></tec-stats>
			<tec-stats
				[title]="'COMPANIES.TITLE' | translate"
				[value]="companies"
				[tagImage]="{ icon: 'icon-store', status: 'level' }"
				[size]="'default'"
			></tec-stats>
			<tec-stats
				[title]="'SALES.BUSINESS' | translate"
				[value]="business"
				[tagImage]="{ icon: 'icon-briefcase', status: 'level' }"
				[size]="'default'"
			></tec-stats>
			<tec-stats
				[title]="'SALES.CLIENTS' | translate"
				[value]="clients"
				[tagImage]="{ icon: 'icon-customer', status: 'level' }"
				[size]="'default'"
			></tec-stats>
		</div>
	</ng-container>

	<!-- Graficos y comisiones -->
	<ng-container dashboard *ngIf="!isSuperAdmin; else tecalisAdminGraph">
		<div class="layout--50-50">
			<div class="layout__column">
				<tec-tile [title]="'CONTACTS.YOUR_CONTACTS' | translate">
					<tec-chart-line
						[dataset]="[{ name: '', data: contacts_chart.dataset[0].data }]"
						[labels]="contacts_chart.labels"
						[initOptions]="{ height: 325 }"
						[options]="{}"
					>
						></tec-chart-line
					>
				</tec-tile>
			</div>
			<div class="layout__column">
				<tec-tile
					type="default"
					icon="icon-percentange-circle"
					styling="default"
					[title]="'HEADER.YOUR_COMISSIONS' | translate"
				>
					<ng-container>
						<div class="grid grid-e2-sm">
							<tec-stats
								[title]="'DASHBOARD.GENERATE_THIS_MONTH' | translate"
								[value]="comissions.total"
								[size]="'sm'"
								[ghost]="true"
							></tec-stats>
							<tec-stats
								[title]="'DASHBOARD.OUTSTANDING_RECEIVABLES' | translate"
								[value]="comissions.pending"
								[size]="'sm'"
							></tec-stats>
						</div>
					</ng-container>
				</tec-tile>
				<tec-tile icon="icon-customer" [title]="'SALES.YOUR_CLIENTS' | translate">
					<ng-container>
						<div class="grid grid-e2-sm">
							<tec-stats
								[title]="'DASHBOARD.TRANSACTIONS_THIS_MONTH' | translate"
								[value]="clients_resume.operations"
								[size]="'sm'"
								[ghost]="true"
							></tec-stats>
							<tec-stats
								[title]="'DASHBOARD.CONSUMPTION_THIS_MONTH' | translate"
								[value]="clients_resume.consumn || ''"
								[size]="'sm'"
							></tec-stats>
						</div>
					</ng-container>
				</tec-tile>
			</div>
		</div>
	</ng-container>

	<ng-template #tecalisAdminGraph>
		<tec-tile [title]="'SALES.EVOLUTION_CONSUMPTION' | translate">
			<tec-chart-line
				class="fix-chart-height"
				[dataset]="[{ name: '', data: all_clients_evolution.dataset[0].data }]"
				[labels]="all_clients_evolution.labels"
				[initOptions]="{ height: 325 }"
				[options]="{}"
			>
				></tec-chart-line
			>
		</tec-tile>
	</ng-template>

	<ng-container dashboard *ngIf="isSuperAdmin">
		<div class="layout--50-50">
			<div class="template-list__content__section">
				<tec-tile [title]="'PARTNERS.LATEST_SETTLEMENT_REQUESTS' | translate">
					<ng-container actions>
						<tec-button type="outline" icon="icon-arrow-right">{{ 'GENERAL.SEE_ALL' | translate }}</tec-button>
					</ng-container>
					<tec-table
						[config]="{
							clickeable: false,
							checkbox: false
						}"
						[headers]="[{ label: 'PARTNERS.NAME' | translate }, { label: 'PARTNERS.IMPORT' | translate }]"
						[rows]="pending_table"
					></tec-table>
				</tec-tile>
			</div>
			<div>
				<tec-tile [title]="'PARTNERS.PARTNERS' | translate" icon="icon-partner" class="template-detail__content">
					<ng-container>
						<div class="layout--50-50">
							<tec-stats [title]="'SALES.TOTAL' | translate" [value]="partners.total" [size]="'sm'" [ghost]="true"></tec-stats>
							<tec-stats [title]="'PARTNERS.NEW_THIS_MONTH' | translate" [value]="partners.new" [size]="'sm'"></tec-stats>
						</div>
					</ng-container>
				</tec-tile>
				<tec-tile [title]="'SALES.CLIENTS' | translate" icon="icon-customer" class="template-detail__content">
					<ng-container>
						<div class="layout--50-50">
							<tec-stats
								[title]="'DASHBOARD.TRANSACTIONS_THIS_MONTH' | translate"
								[value]="clients_resume.operations"
								[size]="'sm'"
								[ghost]="true"
							></tec-stats>
							<tec-stats
								[title]="'DASHBOARD.CONSUMPTION_THIS_MONTH' | translate"
								[value]="clients_resume.usage || ''"
								[size]="'sm'"
							></tec-stats>
						</div>
					</ng-container>
				</tec-tile>
			</div>
		</div>
	</ng-container>

	<!-- Tablas top clientes y negocios -->
	<ng-container dashboard *ngIf="!isSuperAdmin">
		<div class="layout--50-50">
			<tec-tile [title]="'DASHBOARD.TOP_CLIENTS' | translate">
				<ng-container actions>
					<tec-button type="outline" icon="icon-arrow-right">{{ 'GENERAL.SEE_ALL' | translate }}</tec-button>
				</ng-container>
				<div class="">
					<ng-container actions>
						<tec-table
							[config]="{
								clickeable: false,
								checkbox: false
							}"
							[headers]="[{ label: 'CONTACTS.NAME' | translate }, { label: 'DASHBOARD.COMMISSIONS_MONTH' | translate }]"
							[rows]="clients_table"
						></tec-table>
					</ng-container>
				</div>
			</tec-tile>
			<tec-tile [title]="'DASHBOARD.TOP_BUSINESS' | translate">
				<ng-container actions>
					<tec-button type="outline" icon="icon-arrow-right">{{ 'GENERAL.SEE_ALL' | translate }}</tec-button>
				</ng-container>
				<div>
					<ng-container actions>
						<tec-table
							[config]="{
								clickeable: false,
								checkbox: false
							}"
							[headers]="[{ label: 'COMPANIES.NAME' | translate }, { label: 'FILTERS.STAGE' | translate }]"
							[rows]="business_table"
						></tec-table>
					</ng-container>
				</div>
			</tec-tile>
		</div>
	</ng-container>
</tec-dashboard-layout>
<ng-template #spinner>
	<tec-spinner [show]="true" [opaque]="true" [watermark]="true"></tec-spinner>
</ng-template>
