<tec-modal
	[isVisible]="true"
	type="confirmation"
	[buttonSecondaryLabel]="'GENERAL.CANCEL' | translate"
	[buttonPrimaryLabel]="'GENERAL.RETRY' | translate"
	[buttonPrimaryStyle]="true"
	(closeEvent)="dialogDeleteAction($event)"
>
	<ng-container body>
		<tec-tag-icon [size]="'default'" [icon]="'icon-alert'"></tec-tag-icon>
		<div class="body__text">
			<h4 [innerHTML]="'ERRORS.ERROR_TITLE' | translate"></h4>
			<span [innerHTML]="'ERRORS.ERROR_DESCRIPTION' | translate"></span>
		</div>
	</ng-container>
</tec-modal>
