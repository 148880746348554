import { CommonModule, CurrencyPipe, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ReactiveFormsModule } from '@angular/forms';
import {
	AlertActionComponent,
	AlertComponent,
	AliceComponent,
	AuthLayoutComponent,
	AvatarComponent,
	AvatarGroupComponent,
	BadgeComponent,
	BaseComponent,
	BreadcrumbComponent,
	ButtonCollapseComponent,
	ButtonComponent,
	ButtonIconComponent,
	CardAccessComponent,
	CardComponent,
	ChartBarComponent,
	ChartComponent,
	ChartLineComponent,
	ChartPieComponent,
	CheckboxComponent,
	ChipsComponent,
	CodeInputComponent,
	CollapseComponent,
	CommentsComponent,
	CopyTextDirective,
	CreateLayoutComponent,
	DashboardComponent,
	DatepickerComponent,
	DatepickerHeaderComponent,
	DetailAsideLayoutComponent,
	DetailLayoutComponent,
	DividerComponent,
	EmptyStateComponent,
	FilterComponent,
	FooterComponent,
	FormGroupComponent,
	IconComponent,
	InputComponent,
	InputSearchComponent,
	ItemComponent,
	ItemGroupComponent,
	LinkComponent,
	ListDetailComponent,
	ListLayoutComponent,
	MenuComponent,
	ModalComponent,
	PaginationComponent,
	ProfileComponent,
	ProgressComponent,
	RateAlertComponent,
	RateComponent,
	RateStatusComponent,
	SelectComponent,
	SidenavComponent,
	SliderComponent,
	SnackbarComponent,
	SpinnerComponent,
	StatsComponent,
	StepperProgressComponent,
	SwitchComponent,
	TableComponent,
	TabsComponent,
	TagComponent,
	TagIconComponent,
	TagImageComponent,
	TextEditorComponent,
	TextareaComponent,
	TileComponent,
	TitleGroupComponent,
	ToggleComponent,
	ToolbarComponent,
	TooltipComponent,
	TopnavComponent,
	UploadComponent,
	WatermarkComponent,
} from '@tecalisdev/infinity';
import { ProfileRoutingModule } from './profile-routing.module';
import { ProfileUserComponent } from './profile.component';

@NgModule({
	declarations: [ProfileUserComponent],
	imports: [
		CommonModule,
		ProfileRoutingModule,
		DashboardComponent,
		AlertActionComponent,
		AlertComponent,
		AliceComponent,
		AuthLayoutComponent,
		AvatarComponent,
		AvatarGroupComponent,
		BadgeComponent,
		BaseComponent,
		BreadcrumbComponent,
		ButtonCollapseComponent,
		ButtonComponent,
		ButtonIconComponent,
		CardAccessComponent,
		CardComponent,
		ChartBarComponent,
		ChartComponent,
		ChartLineComponent,
		ChartPieComponent,
		CheckboxComponent,
		ChipsComponent,
		CodeInputComponent,
		CollapseComponent,
		CommentsComponent,
		CopyTextDirective,
		CreateLayoutComponent,
		DatepickerComponent,
		DatepickerHeaderComponent,
		DetailAsideLayoutComponent,
		DetailLayoutComponent,
		DividerComponent,
		EmptyStateComponent,
		FilterComponent,
		FooterComponent,
		FormGroupComponent,
		IconComponent,
		InputComponent,
		InputSearchComponent,
		ItemComponent,
		ItemGroupComponent,
		LinkComponent,
		ListDetailComponent,
		ListLayoutComponent,
		TitleGroupComponent,
		MenuComponent,
		ModalComponent,
		PaginationComponent,
		ProfileComponent,
		ProgressComponent,
		RateAlertComponent,
		RateComponent,
		RateStatusComponent,
		SelectComponent,
		SidenavComponent,
		SliderComponent,
		SnackbarComponent,
		SpinnerComponent,
		StatsComponent,
		StepperProgressComponent,
		SwitchComponent,
		TableComponent,
		TabsComponent,
		TagComponent,
		TagIconComponent,
		TagImageComponent,
		TextareaComponent,
		TileComponent,
		ToggleComponent,
		ToolbarComponent,
		TooltipComponent,
		TopnavComponent,
		UploadComponent,
		WatermarkComponent,
		TranslateModule,
		TextEditorComponent,
		ReactiveFormsModule
	],
	providers: [DatePipe, CurrencyPipe],
})
export class ProfileUserModule {}
