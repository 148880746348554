import { SocialAuthService } from '@abacritt/angularx-social-login';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { AuthService } from '../authentication/services/auth.service';
import { ErrorDialogService } from './error-dialog.service';

@Injectable()
export class ServerErrorInterceptor implements HttpInterceptor {
	constructor(
		private readonly auth: AuthService,
		private readonly errorDialogService: ErrorDialogService,
		private readonly googleAuth: SocialAuthService
	) {}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(request).pipe(
			catchError((error: HttpErrorResponse) => {
				if (error.error?.error?.msg === 'Token expired') {
					this.auth.logOut();
					this.googleAuth.signOut();
					return throwError(() => error);
				} else if (error.error.result === 'ko') {
					this.errorDialogService.openDialog();
					return this.errorDialogService.retryAction$.pipe(switchMap(() => this.intercept(request, next)));
				} else {
					return throwError(() => error);
				}
			})
		);
	}
}
