import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Master } from '../models/master.model';

@Injectable({
	providedIn: 'root',
})
export class MasterService {
	protected http: HttpClient;
	private selectedBusinessSubject = new BehaviorSubject<any | undefined>(undefined);
	selectedBusiness$: Observable<any | undefined> = this.selectedBusinessSubject.asObservable();

	constructor(http: HttpClient) {
		this.http = http;
	}

	getMasterValues(): Observable<Master> {
		return this.http.get<Master>(environment.apiUrl + `/master`).pipe(
			map((masterData) => ({
				...masterData,
				products: masterData.products.map((product) => ({ ...product, id: product.id.toString(), label: product.name })),
				pipeline_status: masterData.pipeline_status.map((status) => ({ ...status, label: status.name })),
				company_sizes: masterData.company_sizes.map((types) => ({ ...types, label: types.name })),
				countries: masterData.countries.map((country) => ({ ...country, label: country.name })),
				amount_filter: masterData.amount_filter.map((types) => ({ ...types, label: types.name })),
				document_type: masterData.document_type.map((document) => ({ ...document, label: document.name })),
				partner_types: masterData.partner_types.map((partner_type) => ({ ...partner_type, label: partner_type.name })),
				last_operation: masterData.last_operation.map((last_operation) => ({
					...last_operation,
					label: last_operation.name,
				})),
				company_category: masterData.company_category.map((category) => ({ ...category, label: category.name })),
				timezones: masterData.timezones.map((timeZone) => ({ ...timeZone, label: timeZone.name })),
			}))
		);
	}
}
