import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { of } from 'rxjs'; // Importar 'of' para devolver un observable vacío si falla
import { catchError, exhaustMap, filter, map, withLatestFrom } from 'rxjs/operators'; // Importar el operador correctamente
import { MasterService } from '../../services/master.service';
import { MasterActions } from './master.action';
import { master } from './master.selector';

@Injectable()
export class MasterEffects {
	constructor(private actions$: Actions, private readonly masterService: MasterService, private store: Store) {}

	loadMaster$ = createEffect(() =>
		this.actions$.pipe(
			ofType(MasterActions.getMasterValues),
			withLatestFrom(this.store.pipe(select(master))),
			filter(([action, masterValues]) => masterValues.pipeline_status.length === 0 && masterValues.products.length === 0),
			exhaustMap(() =>
				this.masterService.getMasterValues().pipe(
					map((master: any) => {
						MasterActions.setRequestId({ request_id: master.request_id });
						return MasterActions.getMasterValuesSuccess({ master });
					}),
					catchError((error) => of(MasterActions.getMasterValuesFailure({ error })))
				)
			)
		)
	);
}
export * from './master.effect';
