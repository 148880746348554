import { createAction, props } from '@ngrx/store';
import { Master } from '../../models/master.model';

export const getMasterValuesSuccess = createAction(
	'[API Tecalis] get Master Value Successfuly',
	props<{ master: Master }>()
);
export const getMasterValuesFailure = createAction('[API Tecalis] get Master Value Failure', props<{ error: Error }>());

export const getMasterValues = createAction('[API Tecalis] get Master Value');
export const companies = createAction('[API Tecalis] gotasdf');
export const setRequestId = createAction('[API Tecalis] set call Request ID', props<{ request_id: string }>());

export * as MasterActions from './master.action';
