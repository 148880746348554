import { Location } from '@angular/common';
import { Injectable, inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { CookiesOptions } from '../models/cookies-options.model';

@Injectable()
export class SSOAuth {
	cookiesOptions: CookiesOptions = {
		expires: undefined,
		path: '/',
		domain: undefined,
		secure: true,
		sameSite: 'Strict',
	};

	constructor(
		public router: Router,
		private readonly cookieService: CookieService,
		private readonly location: Location
	) {}

	canActivate(): boolean {
		const url = this.location.path();
		const chunk = url.split('/');
		const token = chunk[3];
		const today = new Date();
		const tokenExpiredDate = new Date(today.getTime() + 7 * 24 * 60 * 60 * 1000).getTime();

		this.cookieService.set(
			'token',
			token,
			tokenExpiredDate,
			this.cookiesOptions.path,
			this.cookiesOptions.domain,
			this.cookiesOptions.secure,
			this.cookiesOptions.sameSite
		);
		this.router.navigate(['home']);
		return true;
	}
}

export const isLogged: CanActivateFn = () => {
	return inject(SSOAuth).canActivate();
};
