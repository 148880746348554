import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, firstValueFrom } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class LanguageService {
	private readonly defaultLanguage = 'es';
	private readonly allowedLanguages = ['es', 'en'];
	private selectedLanguageSubject = new BehaviorSubject<string | undefined>(undefined);
	selectedLanguage$ = this.selectedLanguageSubject.asObservable();

	constructor(private translateService: TranslateService) {}

	setLanguage(lang: string) {
		this.selectedLanguageSubject.next(lang);
	}

	async getLanguage() {
		return await firstValueFrom(this.selectedLanguageSubject);
	}

	changeLanguage(lang: string) {
		this.setLanguage(lang);
		this.selectedLanguage$.subscribe((language) => (language ? this.translateService.use(language) : this.translateService.setDefaultLang(lang)));
	}

	autoResolveLanguage() {
		this.translateService.addLangs(this.allowedLanguages);
		const lang = this.translateService.getBrowserLang();
		this.changeLanguage(lang ?? this.defaultLanguage);
	}
}
