import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class SpinnerService {
	private isLoadingSubject = new BehaviorSubject<boolean>(false);
	isLoading$ = this.isLoadingSubject.asObservable();

	constructor() {}

	show() {
		this.isLoadingSubject.next(true);
	}

	hide() {
		this.isLoadingSubject.next(false);
	}
}
