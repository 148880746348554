import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
	providedIn: 'root',
})
export class JwtService {
	constructor(private readonly http: HttpClient, private readonly cookieService: CookieService) {}

	decompileToken(token?: string): any {
		const [headerEncoded, payloadEncoded, signature] = token?.split('.') ?? this.cookieService.get('token').split('.');

		return JSON.parse(atob(payloadEncoded));
	}
}
