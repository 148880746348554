<tec-detail-layout [title]="'PROFILE.MY_PROFILE' | translate">
	<ng-container id="section-left">
		<tec-tile type="column" icon="icon-user" [title]="'PROFILE.MY_PROFILE' | translate">
			<ng-container actions *ngIf="isAdmin">
				<tec-link [label]="'GENERAL.EDIT' | translate" (click)="editProfile()"></tec-link>
			</ng-container>
			<ng-container>
				<div class="tile__body__column">
					<tec-avatar
						[avatar]="{ id: '64', img: image, name: nameAvatar }"
						[size]="'lg'"
						[buttonChange]="true"
						(changeImage)="changeImage($event)"
					>
						<tec-link [label]="'GENERAL.REMOVE' | translate" (click)="removeAvatar(1)"></tec-link>
					</tec-avatar>
					<form [formGroup]="imageForm">
						<tec-upload
							style="display: none"
							class="upload__image"
							[size]="'default'"
							[formControlName]="'avatar'"
							[canBeRemoved]="true"
							[acceptedFileTypes]="['.jpg', '.jpeg', '.png']"
							[tagImageStatus]="'default'"
							(fileSelected)="fileSelected($event, 1)"
						></tec-upload>
					</form>
				</div>
				<div class="tile__body__column">
					<div class="grid grid-i2">
						<div class="info-group">
							<b>{{ 'PROFILE.NAME' | translate }}</b>
							<span>{{ name }} </span>
						</div>
						<div class="info-group">
							<b>{{ 'PROFILE.SURNAME' | translate }}</b>
							<span>{{ surname }} </span>
						</div>
						<div class="info-group">
							<b>{{ 'PROFILE.EMAIL' | translate }}</b>
							<span><tec-icon [name]="'icon-mail'" [size]="'xs'"></tec-icon>{{ email }} </span>
						</div>
						<div class="info-group">
							<b>{{ 'PROFILE.PASSWORD' | translate }}</b>
							<tec-link [label]="'PROFILE.CHANGE' | translate" (click)="showPasswordChange()" *ngIf="!showPassword"></tec-link>
							<span *ngIf="showPassword">{{ password }} </span>
						</div>
					</div>
				</div>
			</ng-container>
		</tec-tile>
		<tec-tile type="default" icon="icon-store" styling="default" [title]="'PROFILE.MY_STORE' | translate">
			<div class="grid grid-i2">
				<div class="info-group">
					<b>{{ 'PROFILE.COMERCIAL_NAME' | translate }}</b>
					<span>{{ brandName }} </span>
				</div>
				<div class="info-group">
					<b>{{ 'PROFILE.CORPORATE_NAME' | translate }}</b>
					<span>{{ businessName }} </span>
				</div>
				<div class="info-group">
					<b>{{ 'PROFILE.CIF' | translate }}</b>
					<span>{{ cif }} </span>
				</div>
				<div class="info-group">
					<b>{{ 'PROFILE.BILLING_EMAIL' | translate }}</b>
					<span><tec-icon [name]="'icon-mail'" [size]="'xs'"></tec-icon>{{ billingEmail }} </span>
				</div>
			</div>
		</tec-tile>
	</ng-container>
	<ng-container id="section-right">
		<tec-tile type="default" icon="icon-info" styling="default" [title]="'GENERAL.INFO' | translate">
			<div class="grid grid-i2">
				<div class="info-group">
					<b>{{ 'PROFILE.POSITION' | translate }}</b>
					<span>{{ charge }} </span>
				</div>
				<div class="info-group">
					<b>{{ 'PROFILE.PHONE' | translate }}</b>
					<span><tec-icon [name]="'icon-phone'" [size]="'xs'"></tec-icon>{{ phone }} </span>
				</div>
			</div>
		</tec-tile>
	</ng-container>
</tec-detail-layout>

<!-- Modal de edición del perfil -->
<tec-modal
	[isVisible]="isVisibleModalEdit"
	[title]="'PROFILE.MY_PROFILE' | translate"
	[buttonSecondaryLabel]="'GENERAL.CANCEL' | translate"
	[buttonPrimaryLabel]="'GENERAL.SAVE' | translate" 
	(closeEvent)="closeDialogModalEdit($event)"
>
	<tec-tile [type]="'column'">
		<ng-container body>
			<div class="tile__body__column">
				<tec-avatar
					[avatar]="{ id: '64', img: image2, name: nameAvatar }"
					[size]="'lg'"
					[buttonChange]="true"
					(changeImage)="changeImage($event)"
				>
					<tec-link [label]="'GENERAL.REMOVE' | translate" (click)="removeAvatar(2)"></tec-link>
				</tec-avatar>
				<form [formGroup]="imageForm">
					<tec-upload
						style="display: none"
						formControlName="avatar"
						class="upload__image"
						[size]="'default'"
						[formControlName]="'avatar'"
						[canBeRemoved]="true"
						[acceptedFileTypes]="['.jpg', '.jpeg', '.png']"
						[tagImageStatus]="'default'"
						(fileSelected)="fileSelected($event, 2)"
					></tec-upload>
				</form>
			</div>
			<form [formGroup]="editForm">
				<div class="tile__body__column">
					<div class="grid grid-i2">
						<div class="info-group">
							<b>{{ 'PROFILE.NAME' | translate }}</b>
							<tec-input [value]="name" [type]="'text'" formControlName="name"></tec-input>
						</div>
						<div class="info-group">
							<b>{{ 'PROFILE.SURNAME' | translate }}</b>
							<tec-input [value]="surname" [type]="'text'" formControlName="surname"></tec-input>
						</div>
						<div class="info-group">
							<b>{{ 'PROFILE.EMAIL' | translate }}</b>
							<tec-input [value]="email" [type]="'email'" formControlName="email"></tec-input>
						</div>
						<div class="info-group">
							<b>{{ 'PROFILE.PASSWORD' | translate }}</b>
							<tec-link [label]="'PROFILE.CHANGE_PASSWORD' | translate" (click)="showPasswordChangeModal()" *ngIf="!showPasswordModal"></tec-link>
							<tec-input *ngIf="showPasswordModal" [value]="password" [type]="'password'" formControlName="password"></tec-input>
						</div>
					</div>
				</div>
			</form>
		</ng-container>
	</tec-tile>
</tec-modal>
