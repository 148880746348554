import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { ErrorModalComponent } from 'src/app/shared/components/error-modal/error-modal.component';

@Injectable({
	providedIn: 'root',
})
export class ErrorDialogService {
	retryAction$: Subject<void> = new Subject<void>();

	constructor(private dialog: MatDialog) {}

	openDialog(): void {
		this.dialog
			.open(ErrorModalComponent)
			.afterClosed()
			.subscribe((result) => {
				if (result === 'retry') {
					this.retryAction$.next();
				}
			});
	}
}
