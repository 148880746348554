import { CurrencyPipe } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ITableRow } from '@tecalisdev/infinity';
import { CookieService } from 'ngx-cookie-service';
import { Observable, tap } from 'rxjs';
import { JwtService } from 'src/app/core/services/jwt/jwt.service';
import { ComissionsResume } from './models/comissions.model';
import { ContactChart } from './models/contact-chart.model';
import { Clients, Dashboard, Partners } from './models/dashboard.model';
import { HomeService } from './services/home.service';

@Component({
	selector: 'app-home',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
	title: string = `${this.trans.instant('DASHBOARD.GREETING')}, ${
		this.jwt.decompileToken(this.cookieService.get('token'))?.data?.name
	}`;
	isSuperAdmin: boolean =
		this.jwt.decompileToken(this.cookieService.get('token'))?.data?.role === 'super_admin' ? true : false || false;
	contacts: string = '0';
	companies: string = '0';
	business: string = '0';
	clients: string = '0';
	partners: Partners = {
		new: '1',
		total: '1',
	};
	dashBoard$: Observable<Dashboard>;

	comissions: ComissionsResume = {
		total: '0',
		pending: '0',
	};

	clients_resume: Clients = {
		consumn: '0',
		operations: '0',
	};

	contacts_chart: ContactChart = {
		labels: [],
		dataset: [
			{
				name: '',
				data: [],
			},
		],
	};

	all_clients_evolution: ContactChart = {
		labels: [],
		dataset: [
			{
				name: '',
				data: [],
			},
		],
	};

	clients_table: ITableRow[] = [];
	business_table: ITableRow[] = [];
	pending_table: ITableRow[] = [];

	constructor(
		private currencyPipe: CurrencyPipe,
		private trans: TranslateService,
		private readonly cookieService: CookieService,
		private jwt: JwtService,
		private homeService: HomeService,
		private readonly change: ChangeDetectorRef,
		private router: Router
	) {}

	ngOnInit(): void {
		this.dashBoard$ = this.getDashboard();
	}

	getDashboard(): Observable<Dashboard> {
		return this.homeService.getDashboardInfo().pipe(
			tap((data: Dashboard) => {
				if (this.isSuperAdmin) {
					this.all_clients_evolution.labels = data.usage_graph
						? data.usage_graph.map((labels) => {
								return labels.date;
						  })
						: [];

					this.all_clients_evolution.dataset[0].data = data.usage_graph
						? data.usage_graph.map((datasets) => {
								return datasets.quantity;
						  })
						: [];

					this.partners = data.partners ? data.partners : this.partners;

					this.clients_resume.operations = `${data.clients.operations.toString().replace(',', '.')}K`;
					this.clients_resume.usage = data?.clients?.usage
						? `${data.clients.usage.toString().replace(',', '.')}K €`
						: '0K €';

					if (data.pending && data.pending.length > 0) {
						this.pending_table = this.fillRowsTable(data, 'pending');
					}
				} else {
					this.contacts = data.contacts_total.toString();
					this.companies = data.companies_total.toString();
					this.business = data.business_total.toString();
					this.clients = data.clients_total.toString();

					this.comissions.total =
						this.currencyPipe.transform(data.comissions.total.toString().replace(',', '.'), 'EUR') || '0';
					this.comissions.pending =
						this.currencyPipe.transform(data.comissions.pending.toString().replace(',', '.'), 'EUR') || '0';

					this.clients_resume.consumn =
						data && data.clients.consumn ? `${data.clients.consumn.toString().replace(',', '.')}K €` : '0K €';
					this.clients_resume.operations = data.clients.operations.toString().replace(',', '.') || '0';

					this.contacts_chart.labels = data.graph_contacts_per_day.map((labels) => {
						return labels.date;
					});
					this.contacts_chart.dataset[0].data = data.graph_contacts_per_day.map((datasets) => {
						return datasets.quantity;
					});

					if (data.top_clients && data.top_clients.length > 0) {
						this.clients_table = this.fillRowsTable(data, 'client');
					}
					if (data.top_business && data.top_business.length > 0) {
						this.business_table = this.fillRowsTable(data, 'business');
					}
				}

				this.change.detectChanges();
			})
		);
	}

	fillRowsTable(data: Dashboard, type: string): ITableRow[] {
		if (type === 'client') {
			return data.top_clients.map((client, index) => {
				return {
					clickValue: `${index + 1}`,
					cells: [
						{
							label: client?.name,
							tagImage: { icon: 'icon-customer' },
						},
						{
							label: this.currencyPipe.transform(client.comission.toString().replace(',', '.'), 'EUR') || '',
						},
					],
				};
			});
		}

		if (type === 'business') {
			return data.top_business.map((business, index) => {
				return {
					clickValue: `${index + 1}`,
					cells: [
						{
							label: business?.name,
							tagImage: { icon: 'icon-briefcase' },
						},
						{
							label: this.trans.instant('DASHBOARD.SCHEDULED_APPOINTMENT'),
							badge: { type: 'square' },
						},
					],
				};
			});
		}

		if (type === 'pending' && data.pending) {
			return data.pending.map((pending, index) => {
				return {
					clickValue: `${index + 1}`,
					cells: [
						{
							label: pending?.name,
							tagImage: { icon: 'icon-partner' },
						},
						{
							label: this.currencyPipe.transform(pending.comission.toString().replace(',', '.'), 'EUR') || '',
						},
					],
				};
			});
		}

		return [];
	}

	createContacts() {
		this.router.navigate(['sales/contacts/create']);
	}

	createCompany() {
		this.router.navigate(['sales/companies/create']);
	}

	createBusiness() {
		this.router.navigate(['sales/business/create']);
	}

	createPartners() {
		this.router.navigate(['']);
	}

	createUser() {
		this.router.navigate(['']); //pendiente de ver que se hace, por ahora nada
	}

	goToPipeLine() {
		this.router.navigate(['sales/pipelines']);
	}

	goToClients() {
		this.router.navigate(['sales/clients']);
	}

	goToBusiness() {
		this.router.navigate(['sales/business']);
	}

	goToCompanies() {
		this.router.navigate(['sales/companies']);
	}
}
