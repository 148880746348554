import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { AlertComponent, AuthLayoutComponent, ButtonComponent, CodeInputComponent } from '@tecalisdev/infinity';
import { catchError, throwError } from 'rxjs';
import { AuthService } from 'src/app/core/authentication/services/auth.service';

@Component({
	selector: 'app-check-otp',
	templateUrl: './check-otp.component.html',
	styleUrls: ['./check-otp.component.scss'],
	standalone: true,
	imports: [
		CommonModule,
		AuthLayoutComponent,
		TranslateModule,
		AlertComponent,
		CodeInputComponent,
		ButtonComponent,
		RouterModule,
	],
	providers: [AuthService],
})
export class CheckOtpComponent {
	codeOtp: any = '';
	public codeOtpError: string = '';
	public errorOtp: string = '';
	public email: string = '';

	constructor(readonly auth: AuthService, private route: ActivatedRoute, private router: Router) {
		this.route.params.subscribe((params) => {
			this.email = params['email'];
		});
	}

	public onContinue(): void {
		if (this.codeOtp)
			this.auth
				.checkOTP({ username: this.email, code: this.codeOtp })
				.pipe(
					catchError((error) => {
						this.errorOtp = error?.error.error.msg;
						return throwError(() => error);
					})
				)
				.subscribe(() => this.router.navigate(['home']));
	}

	closeAlert($event: any) {}
}
