import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import {
	AlertComponent,
	AuthLayoutComponent,
	ButtonCollapseComponent,
	ButtonComponent,
	CheckboxComponent,
	CodeInputComponent,
	DividerComponent,
	FormGroupComponent,
	IconComponent,
	InputComponent,
	LinkComponent,
	SpinnerComponent,
	WatermarkComponent,
} from '@tecalisdev/infinity';
import { AuthService } from 'src/app/core/authentication/services/auth.service';
import { SpinnerService } from 'src/app/shared/services/spinner.service';
import { passwordRegex } from 'src/app/shared/validators';
import { CheckOtpComponent } from '../check-otp/check-otp.component';

@Component({
	selector: 'app-register',
	templateUrl: './register.component.html',
	styleUrls: ['./register.component.scss'],
	standalone: true,
	imports: [
		CommonModule,
		TranslateModule,
		AuthLayoutComponent,
		IconComponent,
		LinkComponent,
		ButtonComponent,
		InputComponent,
		AlertComponent,
		DividerComponent,
		ButtonCollapseComponent,
		CheckboxComponent,
		FormGroupComponent,
		FormsModule,
		ReactiveFormsModule,
		SpinnerComponent,
		CodeInputComponent,
		CheckOtpComponent,
		RouterModule,
		WatermarkComponent,
		ButtonCollapseComponent,
	],
	providers: [AuthService, SpinnerService],
})
export class RegisterComponent implements OnInit {
	form: FormGroup;
	step = 1;

	constructor(private router: Router, private readonly fb: FormBuilder, private readonly cd: ChangeDetectorRef) {
		this.form = this.fb.group({
			name: ['', Validators.required],
			surnames: ['', Validators.required],
			email: ['', [Validators.required, Validators.email]],
			newPassword: ['', [Validators.required, Validators.pattern(passwordRegex())]],
			confirmNewPassword: ['', [Validators.required, Validators.pattern(passwordRegex())]],
		});
	}

	ngOnInit(): void {}

	public onContinue() {
		// if (!this.form.valid) {
		// 	this.form.markAllAsTouched();
		// 	return;
		// }

		this.router.navigate(['/OTP', this.emailFromControl.value]);
	}

	goToEmail() {
		this.step = 2;
	}
	goToGoogle() {
		this.step = 2;
	}

	get nameFromControl(): FormControl {
		return this.form.get('name') as FormControl;
	}

	get surnamesFromControl(): FormControl {
		return this.form.get('surnames') as FormControl;
	}

	get emailFromControl(): FormControl {
		return this.form.get('email') as FormControl;
	}

	get newPasswordFromControl(): FormControl {
		return this.form.get('newPassword') as FormControl;
	}

	get confirmNewPasswordFromControl(): FormControl {
		return this.form.get('confirmNewPassword') as FormControl;
	}
}
