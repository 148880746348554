import { SocialAuthService } from '@abacritt/angularx-social-login';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { Observable, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CookiesOptions } from '../models/cookies-options.model';
import { Login } from '../models/login.model';
import { OTPCredentials } from '../models/otp-credentials.model';

@Injectable({
	providedIn: 'root',
})
export class AuthService {
	protected http: HttpClient;
	cookiesOptions: CookiesOptions = {
		expires: undefined,
		path: '/',
		domain: undefined,
		secure: true,
		sameSite: 'Strict',
	};

	constructor(
		private readonly router: Router,
		http: HttpClient,
		private readonly cookieService: CookieService,
		private readonly googleAuth: SocialAuthService
	) {
		this.http = http;
	}

	public logIn(body: Login): Observable<any> {
		return this.http.post<any>(environment.apiUrl + `/login`, body);
	}

	public checkOTP(otp: OTPCredentials): Observable<any> {
		return this.http.post<any>(environment.apiUrl + `/otp/check`, otp).pipe(
			tap((response) => {
				this.cookieService.set(
					'token',
					response.token,
					new Date(response.cookie_expiration),
					this.cookiesOptions.path,
					this.cookiesOptions.domain,
					this.cookiesOptions.secure,
					this.cookiesOptions.sameSite
				);
				this.cookieService.set(
					'cookie_expiration',
					response.cookie_expiration,
					this.cookiesOptions.expires,
					this.cookiesOptions.path,
					this.cookiesOptions.domain,
					this.cookiesOptions.secure,
					this.cookiesOptions.sameSite
				);
			})
		);
	}

	changePassword(password: string) {
		return this.http.post<any>(environment.apiUrl + `/password/change`, { password });
	}

	sentGoogleToken(token: string) {
		return this.http.post<any>(environment.apiUrl + `/login/google`, { token }).pipe(
			tap((token) => {
				const today = new Date();
				const tokenExpiredDate = new Date(today.getTime() + 7 * 24 * 60 * 60 * 1000).getTime();
				this.cookieService.set(
					'token',
					token.token,
					new Date(tokenExpiredDate),
					this.cookiesOptions.path,
					this.cookiesOptions.domain,
					this.cookiesOptions.secure,
					this.cookiesOptions.sameSite
				);
			})
		);
	}

	public logOut(): void {
		this.cookieService.delete('token', '/');
		this.cookieService.delete('cookie_expiration', '/');
		sessionStorage.removeItem('loggedInUser');
		this.googleAuth.signOut();
		this.router.navigate(['login']);
	}
}
